import React from 'react';
import { InvoiceModel } from '../../../helpers';
import { IInvoicePaymentScheduleEntity } from '../../../../../actions/DataServiceEntities.g';
import { ICultureInfoFormatter } from '@msdyn365-commerce/core';
import { getInvoiceDetailsPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { getInvoiceStatusText } from '../../../helpers/invoice-status';
import { IGdnInvoicesListResources } from '../../../gdn-invoices-list.props.autogenerated';
import { InvoicePaidStatus, InvoiceType } from '@msdyn365-commerce/retail-proxy';

interface InvoiceTileProps {
    invoice: InvoiceModel;
    invoiceBreakdownList: IInvoicePaymentScheduleEntity[];
    cultureFormatter: ICultureInfoFormatter;
    addInvoiceToCart: (invoiceId: string, amount: number) => void;
    actionContext: any;
    resources: IGdnInvoicesListResources;
    cart?: any;
}

export const InvoiceTile: React.FC<InvoiceTileProps> = (props: InvoiceTileProps) => {
    const {
        invoice,
        invoiceBreakdownList,
        cultureFormatter: { formatCurrency, formatDate },
        addInvoiceToCart,
        actionContext,
        resources,
        cart
    } = props;
    if (invoice.id === 'INV00421359') {
        console.log('props', props);
    }

    const breakdown = invoiceBreakdownList.filter(inv => inv.Invoice === invoice.id);
    const calculateTotalAmount = (rows: boolean[]): number => {
        return (
            rows.reduce((total, isSelected, idx) => {
                return isSelected ? total + (breakdown[idx].AmountCur || 0) : total;
            }, 0) - getAllowableFreightAmount()
        );
    };

    const getEarliestInvoiceDue = () =>
        invoiceBreakdownList.reduce((earliest, current) => {
            return earliest.DueDate && current.DueDate && new Date(current.DueDate) < new Date(earliest.DueDate) ? current : earliest;
        }, invoiceBreakdownList[0]);

    const isPastDue = (date?: Date) => {
        const dateToCompare = date || getEarliestInvoiceDue()?.DueDate;
        return dateToCompare && new Date(dateToCompare) < new Date();
    };
    const getAllowableFreightAmount = (): number => {
        const charge = breakdown.find(b => (b.AllowableFreightCharge || 0) > 0)?.AllowableFreightCharge;
        if (!charge) {
            return 0;
        }
        if (isPastDue()) {
            return 0;
        }
        const dateToCompare = getEarliestInvoiceDue()?.DueDate;
        if (dateToCompare) {
            const isBefore15th = new Date() <= new Date(dateToCompare.getFullYear(), dateToCompare?.getMonth(), 15);
            return isBefore15th ? charge : 0;
        }
        return 0;
    };

    const [isCreditNote, setIsCreditNote] = React.useState<boolean>(false);
    const [selectAll, setSelectAll] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<boolean[]>(() => breakdown.map(br => isPastDue(br.DueDate) ?? false));
    const [totalAmount, setTotalAmount] = React.useState<number | null>(
        calculateTotalAmount(selectedRows) || (invoice.amountDue || 0) - getAllowableFreightAmount() || 0
    );
    const [displayedAmount, setDisplayedAmount] = React.useState<string | undefined>(totalAmount?.toFixed(2));

    const isInvoiceInCart = React.useMemo(() => {
        return cart.CartLines.some((line: any) => line.InvoiceId === invoice.id);
    }, [cart, invoice.id]);

    const linkToInvoiceDetails = getInvoiceDetailsPageUrlSync(invoice.id, actionContext);

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        const updatedRows = Array(breakdown.length).fill(checked);
        setSelectedRows(updatedRows);
        setTotalAmount(calculateTotalAmount(updatedRows));
        setDisplayedAmount(calculateTotalAmount(updatedRows).toFixed(2));
    };

    const handleRowSelect = (index: number) => {
        const updatedRows = [...selectedRows];
        updatedRows[index] = !updatedRows[index];
        setSelectedRows(updatedRows);

        const allSelected = updatedRows.every(Boolean);
        const noneSelected = updatedRows.every(checked => !checked);
        setSelectAll(allSelected ? true : noneSelected ? false : allSelected);

        setTotalAmount(calculateTotalAmount(updatedRows));
        setDisplayedAmount(calculateTotalAmount(updatedRows).toFixed(2));
    };

    React.useEffect(() => {
        setIsCreditNote(invoice.invoiceTypeValue === InvoiceType.CreditNoteInvoice);
    }, []);

    React.useEffect(() => {
        setSelectedRows(prevSelectedRows => {
            const newSelections = breakdown.map(br => isPastDue(br.DueDate) ?? false);
            return [...prevSelectedRows, ...newSelections.slice(prevSelectedRows.length)];
        });
    }, [invoiceBreakdownList]);

    const discount = getAllowableFreightAmount();
    const invoiceBreakdown = invoiceBreakdownList.find(item => item.Invoice === invoice.id);
    const newInvoiceBreakdownList = invoiceBreakdownList.filter(item => item.Invoice === invoice.id);
    let allowableFreight = 0;
    if (newInvoiceBreakdownList.length > 1) {
        allowableFreight = newInvoiceBreakdownList.find(item => (item.AllowableFreightCharge || 0) > 0)?.AllowableFreightCharge || 0;
        if (allowableFreight > 0) {
            invoiceBreakdown && (invoiceBreakdown.AllowableFreightCharge = allowableFreight);
        }
    }
    const handleReset = () => {
        const selectedTotal = calculateTotalAmount(selectedRows);
        setTotalAmount(selectedTotal);
        setDisplayedAmount(selectedTotal.toFixed(2));
    };

    const handleButtonDisabled = () => {
        return isInvoiceInCart || invoice.amount! < 0 || invoice.id.startsWith('FTC') || invoice.id.startsWith('CN') || totalAmount === 0;
    };

    const shouldDisplayMessage = () => {
        return isInvoiceInCart;
    };

    const handleBlur = (value: string) => {
        const num = parseFloat(value.replace('$', ''));
        const cleanNum = isNaN(num) ? '0.00' : num.toFixed(2);
        setDisplayedAmount(cleanNum);
    };

    const InvoicePaidStatusMap = {
        [InvoicePaidStatus.None]: 'None',
        [InvoicePaidStatus.Unpaid]: 'Not Paid',
        [InvoicePaidStatus.PartiallyPaid]: 'Not Paid',
        [InvoicePaidStatus.Paid]: 'Paid'
    };

    const convertUTCDateToLocalDate = (date: Date) => {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);
        return newDate;
    };

    return (
        <div key={invoice.id} className={'invoice-listview__tile'}>
            <div className={'invoice-listview__tile__header'}>
                <div className='invoice-details'>
                    <div className='row-1'>
                        <div className='id'>
                            <a
                                className={'invoice-listview__tile--link invoice-listview__tile--bold'}
                                target='_blank'
                                rel='noopener noreferrer'
                                href={linkToInvoiceDetails}
                            >
                                {invoice.id}
                            </a>
                        </div>
                        <div className='invoice-date'>
                            <span className={'invoice-listview__tile--bold'}>Invoice Date:</span> {formatDate(invoice.invoiceDate!)}
                        </div>
                        <div className='sales-order'>
                            <span className={'invoice-listview__tile--bold'}>Sales Order:</span> {invoiceBreakdown?.SalesId}
                        </div>
                    </div>
                    <div className='row-2'>
                        <div className='amount'>
                            <span className={'invoice-listview__tile--bold'}>Amount: </span>
                            {formatCurrency(invoice.amount || 0, invoice.currencyCode)}
                        </div>
                        {!isCreditNote && (
                            <>
                                <div className='terms'>
                                    <span className={'invoice-listview__tile--bold'}>Terms: </span>
                                    {invoiceBreakdown?.Description}
                                </div>
                                <div className='po'>
                                    <span className={'invoice-listview__tile--bold'}>PO: </span>
                                    {invoiceBreakdown?.PurchaseOrder}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!isCreditNote && (
                    <div className='invoice-status'>
                        <div className='status'>
                            {invoice.status !== 3 && isPastDue() ? (
                                <span className={'ml-1 invoice-listview__tile--red invoice-listview__tile--bold'}>Past Due</span>
                            ) : (
                                <span className={'ml-1 invoice-listview__tile--bold'}>
                                    {getInvoiceStatusText(resources, invoice.status)}
                                </span>
                            )}
                        </div>
                        {invoice.status !== 3 && (
                            <div className='due-date'>
                                <span className={'invoice-listview__tile--bold'}>Next Payment Due: </span>
                                {formatDate(convertUTCDateToLocalDate(invoice.dueDate!).toLocaleString())}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {invoice.status !== 3 && !isCreditNote && <div className='header-border' />}

            {/* ticket 8686 */}
            {invoice.status === 3 ? (
                <div className='invoice-listview__tile__invoice-info'>
                    <div className='amount-paid'>
                        <span className={'invoice-listview__tile--bold'}>PAID: </span>${invoice.amount}
                    </div>
                </div>
            ) : (
                <div className='invoice-listview__tile__invoice-info'>
                    {discount ? (
                        <div className='discount'>
                            <span className={'invoice-listview__tile--bold'}>Discount:</span>{' '}
                            {formatCurrency(discount, invoice.currencyCode)}
                        </div>
                    ) : null}
                    {/* {discount > 0 ? (
                        <p>
                            <span className={'invoice-listview__tile--bold'}>Discount Amount: </span>
                            {formatCurrency(discount, invoice.currencyCode)}
                        </p>
                    ) : null} */}
                    <div className='amount-due'>
                        <span className={'invoice-listview__tile--bold'}>Amount Due: </span>
                        {formatCurrency(invoice.amountDue || 0, invoice.currencyCode)}
                    </div>
                    {breakdown.length > 0 ? (
                        <div className={'invoice-listview__tile__breakdown'}>
                            <table className={'invoice-listview__tile__table'}>
                                <thead>
                                    <tr>
                                        <th className='checkbox'>
                                            <input type='checkbox' onChange={handleSelectAll} checked={selectAll} />
                                        </th>
                                        <th>Payment</th>
                                        <th>Due</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {breakdown.map((br, idx) => {
                                        const allowableFreightCharge = getAllowableFreightAmount();
                                        const allowableFreightChargeActual = br.AllowableFreightCharge || 0;
                                        const earliestInvoiceDate = getEarliestInvoiceDue()?.DueDate;
                                        const allowableFreightDueDate =
                                            earliestInvoiceDate &&
                                            new Date(earliestInvoiceDate.getFullYear(), earliestInvoiceDate?.getMonth(), 15);
                                        return (
                                            <React.Fragment key={idx}>
                                                {allowableFreightCharge && allowableFreightChargeActual ? (
                                                    <tr className='invoice-listview__freight-row'>
                                                        <td className='checkbox'>
                                                            <input
                                                                type='checkbox'
                                                                checked={selectedRows[idx]}
                                                                onChange={() => handleRowSelect(idx)}
                                                            />
                                                        </td>
                                                        <td>{formatCurrency(-allowableFreightChargeActual, invoice.currencyCode)}</td>
                                                        {allowableFreightDueDate && (
                                                            <td className='freight-charge'>{formatDate(allowableFreightDueDate)}</td>
                                                        )}
                                                        <td>Allowable Freight Discount if Paid by 15th</td>
                                                    </tr>
                                                ) : (
                                                    undefined
                                                )}
                                                <tr>
                                                    <td className='checkbox'>
                                                        <input
                                                            type='checkbox'
                                                            checked={selectedRows[idx]}
                                                            onChange={() => handleRowSelect(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {br.AmountCur === invoice.amount
                                                            ? formatCurrency(br.AmountCur || 0, invoice.currencyCode)
                                                            : `${formatCurrency(
                                                                  br.AmountCur || 0,
                                                                  invoice.currencyCode
                                                              )} remaining of ${formatCurrency(invoice.amount || 0, invoice.currencyCode)}`}
                                                    </td>
                                                    <td>{formatDate(convertUTCDateToLocalDate(br.DueDate!).toLocaleString())}</td>
                                                    <td
                                                        className={
                                                            isPastDue(br.DueDate)
                                                                ? 'ml-1 invoice-listview__tile--red invoice-listview__tile--bold'
                                                                : ''
                                                        }
                                                    >
                                                        {isPastDue(br.DueDate) ? 'PAST DUE' : InvoicePaidStatusMap[invoice.status!]}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                    {!isCreditNote && (
                        <>
                            <div className='input'>
                                <div>
                                    <span className={'invoice-listview__tile--bold'}>Pay:</span>

                                    <input
                                        className='amount-input'
                                        type='text'
                                        id='amountInput'
                                        defaultValue={isPastDue() ? `$${displayedAmount}` : '0.00'}
                                        onBlur={e => handleBlur(e.target.value)}
                                        onChange={e => {
                                            let value = e.target.value;
                                            value = value.replace('$', '');
                                            setDisplayedAmount(value);
                                            if (Number(value) < 0) {
                                                setTotalAmount(0);
                                                setDisplayedAmount('0.00');
                                                return;
                                            }
                                            if (Number(value) > calculateTotalAmount(Array(breakdown.length).fill(true))) {
                                                setTotalAmount(calculateTotalAmount(selectedRows));
                                                setDisplayedAmount(calculateTotalAmount(selectedRows).toFixed(2));
                                                return;
                                            }
                                            setTotalAmount(value === '' ? null : Number(value));
                                        }}
                                    />
                                </div>
                                <button disabled={handleButtonDisabled()} onClick={() => addInvoiceToCart(invoice.id, totalAmount!)}>
                                    Add to Cart
                                </button>
                            </div>
                            <div className='reset-amount-div'>
                                {totalAmount === calculateTotalAmount(selectedRows) ? (
                                    <div className='specify-amount-text'>
                                        Type in box to specify amount
                                        {shouldDisplayMessage() && <span className='payment-added-inline'>Payment added to cart</span>}
                                    </div>
                                ) : (
                                    <div className='reset-amount-text' onClick={handleReset}>
                                        Reset to selected amount
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
